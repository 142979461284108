#timeoutMessage{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:9999;
	display:none;
    @include transparentBG($color:$light, $opacity:0.70);
    .message{
        width:100%;
        //max-width:500px;
        position:absolute;
        left:0;
        top:200px;
        margin:0 0 0 0px;
        /* @include boxShadow;
        @include boxBorder; */
        @include clearfix;
        padding-bottom: 45px;
        background:$primary;
        @media (max-width:500px){
            left:0;
            margin:0;
        }
    }
    h4{
        font-family:$primaryFont;
        font-size: 3.5em;
        font-weight: 400;
        text-align:center;
        color: #fff;
        margin:20px 0 10px 0;
    }
    .btnWrap{
    	width: 90%;
    	max-width: 600px;
    	margin: 50px auto 20px auto;
    	text-align: center;
    	span{
    		display:inline-block;
    		margin-right:15px;
    		position: relative;
            background: $secondary;
            padding: 10px 20px 10px 20px;
            color: $light;
            font-family:$primaryFont;
            font-size:1.125em;
            text-align:center;
            &:hover{
            	background:darken($secondary,10%);
            }
            &.stayBtn{
            	background:$light;
            	color:$primary;
            	&:hover{
					background:darken($light,10%);
            	}
            	em{
            		&:before{
	            		content:'c';
	            	}
            	}
            }
            em{
            	display:block;
            	font-style:normal;
            	font-weight:600;
            	text-transform:uppercase;
            	font-size:1.25em;
            	&:before{
	            	content:'i';
	            	font-family:$iconFont;
	            	padding-right:10px;
	            	text-transform:none;
	            }
            }
    	}
    }
}