#helpBG{
    display: none;
    @include transparentBG($basic, .7);
    @include fixed;
    @include fillSpace;
    z-index: 9991;
}
#helpContent{
    z-index: 9992;
   display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    @include fillSpace;
    .arrowNav{
        display: block;
    }
}
#helpCarousel{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0px 0px 95px;
    background: $light;
    color: $basic;
    top: -400px;
    @include transition($speed: 1s);
    //border-top: 45px solid $basic;
    
    &.showing{
        top:0px;
    }
    
    .carousel-inner{
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
    }
    .item{
       width: 100%;
       margin: 0 auto;
        padding: 0px 100px 45px;
        margin-top: 30px;
    }
    .titleWrap{
         background: $primary;
        padding: 15px 0px;
        text-align: center;
    }
    h1{
        text-transform: uppercase;
        color: $light;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 1.7em;;
        font-size: 1.2em;
    }

    /* h3{
        display: inline-block;
        margin-bottom: 5px;
        font-family: $primaryFont;
        line-height: 1.3em;
        font-weight: 600;
        font-style: italic;
        font-size: 1.25em;
        color: $basic;
        position: relative;
        padding-left: 0;
        padding-bottom: 5px;
        border-bottom: 3px solid $secondary;
        margin-bottom: 15px;
        &::before{
            @include pseudoContent;
            @include bottomArrow($color:$secondary,$top:auto,$left:-6px,$size:10px); 
            bottom:-10px;
        }
    } */
    .helpSlideTitle{
            margin-bottom: 18px;
        color: $secondary;
        font-family:$primaryFont;
        font-weight: 700;
        font-size: 1.7em;
        font-size: 2.5em;
    }
       ul{
        padding-left: 0;
        margin:18px 0 18px 0;
        list-style:none;
        li {
            padding: 5px 0;
            margin-left:2px;
            line-height:1.35em;
            position: relative;
            padding-left:20px;
            &::before{
                @include pseudoContent;
                @include rightArrow($color:$secondary,$top:10px,$size:6px);
                right:auto;
                left:0;
            }
            li{
                &:before{
                    content:"";
                    background: transparent;
                    border:2px solid $secondary;
                    width:8px;
                    height:8px;
                }
            }
        }
    }
    .copy{
            font-family: $basicFont;
            font-weight: 400;
           font-size: 1.2em;
            line-height: 1.45em;
            color: $dark;

          ul{
              li{
                  padding-bottom: 8px;
              }
            }
        p{
            @include zeroMargPad;
            padding-bottom: 10px;
        }
        img{
            max-width: 100%;
            height: auto;
        }
        h2{
           font-size: 1.8em; 
        }
    }
       
    .helpArrow{
        position: fixed!important;
        left: 0px;
        width: 100%;
        top: 21px;
        height:100%!important;
        margin-top:-25px;
       .arrow{
           position: absolute;
           top: 5px;
           //font-size: 2.5em;
           text-decoration: none !important;
           color: $secondary;
            &.left{
                left: 0;
               
            }
           &.right{
                right: 0;
           }
           span{
                top:9px;
                left:10px;
                color:$light!important;
           }
       }
        
    }

    
    .carousel-indicators {
        z-index: 999;;
        bottom: -15px;
        li{
            border: 2px solid $dark;
            &.active{
                background: $dark;
            }
        }

    }

    
    .helpBtnWrap{
        position: absolute;
        bottom: 10px;
        left: 0px;
        width: 100%;
        text-align: center;
        z-index:999;
        .lbClosebtn{
           @include btnStyle;
            display: inline-block;
            padding: 7px 25px;
            font-size: 1.7em;
            color: $light;
        }

        &.disabled{
            .lbClosebtn{
                 background:$grey;
                 color: lighten($dark, 60%);
                 &:hover{
                    cursor: not-allowed;
                }
            }
        }
        &:hover{
            cursor: pointer;
        }

    }
}


.inSection{
    .helpBtn{
        display: none;
    }
}

@media (max-width: $sm){
   .helpBtn{

   }
    #helpContent{
        background: $light;
        top: 0px;
    }
    #helpCarousel{
        &.showing{
            top: 0px;
        }
        
        h1{
           // padding-top: 10px;
        }
    }
    .helpArrow{
        background:transparent!important;
    }
}

@media (max-width: $xs){
    #helpContent{
        top: 0px;
        position: absolute;
    }
    #helpCarousel{
        padding: 0px 20px 80px;
        font-size: .9em;
        h1{
            max-height: 100%;
            padding-bottom: 15px;
        }
        &::before{
            display: none;
        }
        .titleWrap{
            padding:9px 0 8px 0;
        }
        .helpArrow{
            position: fixed;
            top: 0px;
            height:78px!important;
            .arrow{
                top:25px!important;
                height:53px!important;
            }
        }
        .helpSlideTitle{
            font-size: 1.8em;
        }
        .copy{
                
            li{
                margin-bottom: 7px;
            }
            ul{
                margin: 0px;
            }
            p{
                @include zeroMargPad;
                padding-bottom: 10px;
            }
            img{
                max-width: 100%;
                height: auto;
                @include boxShadow();
            }
            h2{
               font-size: 1.8em; 
            }
        }
        .helpBtnWrap{
            .lbClosebtn{
            font-size: 1.5em;
            }
        }
        .item{
            width: 100%;
            padding: 0 0 45px 0;
            margin-top:10px;
        }
        .helpSlideTitle{
            margin-top: 5px;
            padding-top: 15px;
        }
    }
    
}

@media (max-width: $xxs){
    #helpCarousel {
       
    }
}