@charset "UTF-8";

@font-face {
  font-family: "rgv";
  src:url("/fonts/rgv.eot");
  src:url("/fonts/rgv.eot?#iefix") format("embedded-opentype"),
    url("/fonts/rgv.woff") format("woff"),
    url("/fonts/rgv.ttf") format("truetype"),
    url("/wfonts/rgv.svg#rgv") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "rgv" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "rgv" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\61";
}
.icon-link:before {
  content: "\62";
}
.icon-check:before {
  content: "\63";
}
.icon-announce:before {
  content: "\64";
}
.icon-arrow-left:before {
  content: "\65";
}
.icon-arrow-right:before {
  content: "\66";
}
.icon-info:before {
  content: "\67";
}
.icon-home:before {
  content: "\68";
}
.icon-x:before {
  content: "\69";
}
.icon-quickview:before {
  content: "\6a";
}
.icon-slideview:before {
  content: "\6b";
}
.icon-help:before {
  content: "\6c";
}
.icon-pdf:before {
  content: "\6d";
}
