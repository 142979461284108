#validate {
	@include transparentBG($color:$basic, $opacity:0.85);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	p {
		display: none;
		position: absolute;
		top: 30%;
		left: 0;
		width: 100%;
		text-align: center;
		@include headerFont;
		color: $light;
		font-size: 2em;
		padding: 10px;

		em {
			font-size: 1.3em;
			font-style: normal;
			font-family: $secondaryFont;
			color: $primary;
			text-transform: uppercase;

			h2 {
				font-size: 1.5em;
				color: $secondary;
				text-transform: uppercase;
			}
		}

		@media all and (max-width: $xs) {
			font-size: 1.5em;
		}
	}
}

#timedMessage {
	display: none;
	@include transparentBG($color:$basic, $opacity:0.85);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	p {
		display: none;
		position: absolute;
		top: 30%;
		left: 0;
		width: 100%;
		text-align: center;
		@include headerFont;
		color: $light;
		font-size: 2em;
		padding: 10px;

		.itemName,
		.availableDate {
			font-size: 1.3em;
			font-style: normal;
			font-family: $secondaryFont;
			color: $primary;
			text-transform: uppercase;
			font-size: 1.5em;
			color: $secondary;
			text-transform: uppercase;
		}

		@media all and (max-width: $xs) {
			font-size: 1.5em;
		}
	}
}