#backgroundImg{
	width:60%;
	height:100%;
	position:fixed;
	top:0;
	right:0;
	@include backgroundCover;
	background-color:$light;
	div{
		@include fillSpace;
		@include absolute;
		@include backgroundCover;
		background-position:0% 100%;
	}
	.gradient{
		background: -moz-linear-gradient(180deg, #FFFFFF 100px, transparent 50%);/* FF3.6+ */
		background: -webkit-gradient(linear, 180deg, color-stop(100px, FFFFFF), color-stop(50%, transparent));/* Chrome,Safari4+ */
		background: -webkit-linear-gradient(180deg, #FFFFFF 100px, transparent 50%);/* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(180deg, #FFFFFF 100px, transparent 50%);/* Opera 11.10+ */
		background: -ms-linear-gradient(180deg, #FFFFFF 100px, transparent 50%);/* IE10+ */
		background: linear-gradient(180deg, #FFFFFF 100px, transparent 50%);/* W3C */
		@include fillSpace;
		@include absolute;
		@include backgroundCover;
	}
}

#navBackground{
	position: fixed;
	@include fillSpace;
	width:40%;
	top:0;
	left:0;
	z-index:-1;
	div{
		@include fillSpace;
		@include absolute;
		@include backgroundCover;
		background-position:0% 50%;
		opacity:1!important;
	}
	.overlay{
		@include transparentBG($basic,.6);
	}
}
.navBorder{
	@include dashedBorderRight($light);
	position: fixed;
	@include fillSpace;
	width:40%;
	top:0;
	left:0;
}
@media (max-width:$lg){

}
@media (max-width:$md){
    #navBackground{
		top:130px;
    }
}
@media (max-width:$sm){
	#backgroundImg{
		width:100%!important;
		background-color:black;
		&.inside{
			background-color:$light;
		}
		div{
			@include opacity(50,.5);
		}
	}
    #navBackground{
		width:0%;
    }
    .navBorder{display:none;}
}
@media (max-width:$xs){
    
}