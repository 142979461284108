/*-----ADA Link---------------*/
.adaLink{
    display:block;
    position:fixed;
    bottom:0;
    left:0;
    z-index:999;
    display:block;
    background:$primary;
    color:$light!important;
    padding: 17px 15px;
    font-size:1em;
    text-decoration:none!important;
    @include transition;
    &:hover{
        background:$primary;
        color:$light;
    }
}
@media (max-width:$sm){
    .adaLink{
        bottom: 45px;
        padding: 5px 15px;
        left: auto;
        right: 0;
        background:$dark;
    }
}
