
#learnMoreWrapper {
    display: none; 
    cursor:pointer;
    @include transparentBG($basic,.65);
    padding: 0;
    font-size: .75em;
    margin-top:50px;
    clear:both;
    @include clearfix;
    @include transition;
    @media all and (max-width: 1700px){

    }
    @media all and (max-width: $xs){
       margin:20px 0 10px 0;
    }
    &:hover{
        margin-left:-15px;
        margin-right:-15px;
        background: $basic;
    } 
    &.selected{
        background:$primary;
        &:hover{
            
        }
        .icon{
            color: $light;
            &:before{
                content: 'c';
                color:$light;
            }
            &:hover{
                
            }
        }
    }
    .icon {
        float:left;
        color: $light;
        padding:15px;
        font-size:.75em;
        @include transition;
        @media all and (max-width: $xs){
            
        }
        &:before{
            @include pseudoContent;
            font-family: $iconFont;
            content: 'g';
            font-size: 3.5em;
            text-align:center;
            left:0;
        }
    }
}




.toolTip{
    font-size: 1.25em;
    color:$light;
    font-family:$secondaryFont;
    padding:14px;
    float:left;
    p{
    margin: 0px!important;
    }
    @media all and (max-width: $xs){
        padding: 0 10px 0 65px;
        float: none;
    }
    p {
        /* @media all and (max-width: $xs){
            font-size:.85em!important;
            text-align:right;
        } */
    }
}




.learnMoreLinks{
    list-style:none;
    margin:0;
    padding:0;   
    span {

    }
    li{
        padding:5px 0!important;
        margin:0!important;
        &:before{
            display:none!important;
        }
        &.pdf{
            a{
                &:before{
                    content:"m";
                }
            }
            
            
        }
            a {
            display:block;
            padding:10px 50px;
            background-color:$light;
            color:$primary;
            text-decoration:none;
            font-size:1.2em;
//            @include rounded(6px);
            @include transition;
            @media all and (max-width: $xs){
                padding: 5px 10px 5px 30px;
            }

            &:hover {
                background-color:$dark;
                color:$light;
                &:before{
                    content:"f";
                    color:$light;
                    margin-left: -30px;
                }
            }
            &:before{
                content:"b";
                font-family: $iconFont;
                float:left;
                display:block;
                margin-right:15px;
                margin-left:-35px;
                @include transition();
                color:$primary;
                @media all and (max-width: $xs){
                    margin-right: 10px;
                    margin-left: -26px;
                }
            }
        }
    }
}
.linkGroup{
    background:$primary;
    padding:15px 25px;
    @media all and (max-width: $xs){
        margin: 0 -15px;
    }
    h3{
        color:$light;
        padding:0!important;
        background:transparent!important;
        border-left:0!important;
        font-size:1.5em;
    }
}
.linkGroup.default{
    background: $grey;
    li{
        a{
            border:1px solid darken($grey,15%);
        }
    }
    h3{
        color: $primary;
    }
}
.textCopy{
    h4{
        margin:10px 0;
        border-left:6px solid $primary;
        font-size:1em;
    }
}


@media (max-width:$xs){
    .toolTip{
        margin: 0px !important;
        line-height: 1.25em;
        padding: 5px!important;
    }
}