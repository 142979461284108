/**VARIABLES**/
//Colors
$primary: #fa4c06; //Orange 
$secondary: #65646a; //Gray
$accent: $secondary; 
$alt: $secondary; 
$contrast: $primary; 
$link: $primary; 
$basic: #000;  //Black


$light: #fff;    //White
$dark: #231f20;    //Charcoal Grey
$grey: #eaeded;    //Grey

$mainBG: darken($grey,10%); // grey
$bodyBG: $light; //White

$wrong: #000; //Red
$correct: #008752; // Green


//Fonts
$primaryFont: 'proxima-nova', sans-serif;
$secondaryFont: Arial, "Lucida Grande", sans-serif;
$basicFont: Arial, "Lucida Grande", sans-serif;
$iconFont: 'rgv';

//Media Query Resolutons
$width-xxsm: 471px; 
$width-xsm: 570px;
$width-sm: 768px;
$width-md: 1050px;
$width-lg: 1200px;
$width-xlg: 1500px;


$tn : 500px;
$xs : 767px;
$sm : 991px;
$md : 1200px;
$max : 1400px;

//New Widths
$xxs: 480px; 
$xs: 600px;
$sm: 800px;
$md: 1050px;
$lg: 1280px;
$xl: 1500px;


//Sizes
$progress : 20px;
$headerHeight: 130px;
$footerHeight: 55px;
$infoSize: 50px;

$maxWidth: 1170px;

//Elements
$cursor: pointer;



