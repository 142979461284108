//Make kaltura container take full height/width of videoHolder
.kaltura-player-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

//Background color for progress bar on video timeline
.playkit-player .playkit-seek-bar .playkit-progress-bar .playkit-progress {
  background-color: $primary !important;
}

//Background color for bar of the volume level
.playkit-volume-control-bar .playkit-progress {
  background-color: $primary !important;
}

//The background of the play button, before the Player begins playing
.playkit-pre-playback-play-button {
  background-color: rgba($primary, 0.75) !important;
}

//Customizing the preexisting spinner
.playkit-spinner {
  width: 60px;
  height: 60px;
  border: 6px solid rgba(255, 255, 255, 0.5);
  border-top: 6px solid $primary;
  border-radius: 50%;
  span {
    display: none !important;
  }
}

//Hide rewind/forward buttons
.playkit-no-idle-control {
  display: none !important;
}

//Hide the background of top bar area, no top controls being utilized in base player
.playkit-player .playkit-top-bar {
  background: none !important;
}

//Darken background of bottom bar area more for ADA compliance
.playkit-player .playkit-bottom-bar {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
}
