#tips{
    background: $light;
    padding: 25px 0 15px 0;
    margin:20px 0;
    font-size: .98em;
    &.smTip{
        display:none;
    }
    .innerContent{
        @include dashedBorderTop($primary);
        @include dashedBorderBottom($primary);
    }
}
.tipTitle{
    padding-bottom: 10px;
     h5{
        text-align:center;
        @include mainHeadFont;
        font-size: 1.75em;
        margin-top:-13px;
        span{
            padding:0 10px;
            background:$light;
        }
     }
}
.tip{
    text-align:center;
    padding: 0 20px 20px 20px;
    font-size:1.25em;
    .smTip{
        display:none;
    }
    p{
        margin-bottom: 0px !important;
    }
}
@media (max-width:$sm){
   #tips{
        display:none;
        &.smTip{
            display:block;
        }
    } 
}