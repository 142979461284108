#inner-content {   //important
	position:relative;
    display:none;
    z-index: 100;
    margin: 154px 0 0 0;
    .slideTitle{
        @include titleFont;
        text-transform:none;
        color:$basic;
        text-align: left;
        font-size: 3.25em;
        padding: 0 0 30px 0;
        line-height: 1em;
        //display:none;
    }
    .slideHolder{   //important
        @include absolute;
        margin-top: 0;
        width:100%;
        min-height: 500px;
	    display: none;
        padding: 0 125px 50px 125px;
    }
}


.errorPage .sectionBGs{
    display:block;
    #button1BG{
        display:block;
    }
}


.content{
    font-family: $basicFont;
    font-size: 1.155em;
    line-height: 1.65em;
    font-weight: 400;
    
}



.slide {
    padding: 0 15px 30px 15px;
    @include clearfix;
    &.full{
        .slideCopy{
            width:100%;
        }
    }
    .sectionTitle{
        display:none;
        margin:0!important;
        font-weight: 400;
        color:$primary;
    }
}

.copySlide,.videoSlide{
    .slideCopy,.videoCopy{
        ul{
            margin-left: 0;
            margin-top:5px;
            padding-left:5px;
            list-style:none;
            li {
                padding: 5px 0;
                margin-left:35px;
                line-height:1.35em;
                &:before{
                    content:"";
                    width: 7px;
                    height:7px;
                    margin: 7px 12px 0 -24px;
                    background: $secondary;
                    display:block;
                    float:left;
                    @include rounded(50%);
                }
                li{
                    &:before{
                        content:"";
                        background: transparent;
                        border:1px solid $secondary;
                    }
                }
            }
        }
        a{
            background:lighten($primary,30%);
            color:$dark;
            padding:3px;
            @include rounded(3px);
            @include transition;
            &:hover{
                background:$primary;
                color:$light;
            }
        }
    }
}

.slideSide{
    img{
        border:1px solid $secondary;
    }
}

.imgWrap{
    width: 100%;
    margin: 0 auto;
    img{
        width: 100%;
        height: auto;
    }
}

.table-responsive{
    .table{
        margin-bottom:0;
        &.twoCol{
            max-width:800px;
        }
        thead{
            td{
                border-bottom: 1px solid $dark;
                color:$primary;
                font-size:1.125em;
                p{
                    margin:0;
                }
            }
        }
        td{
            border-top:0;
        }
    }
}


@media (max-width:$lg){

}
@media (max-width:$md){
   
}
@media (max-width:$sm){
    #inner-content{
        .slideHolder{
            padding: 0 50px 50px 50px;
        }
    }
    .slide{
        .sectionTitle{
            display:block;
        }
        .slideTitle{
            font-size:2.75em!important;
        }
    }
    .table-responsive{
        border: 1px solid $dark;
        &:before{
            content:"NOTE: Swipe the table below from right to left to view all of the content.";
            font-weight: bold;
            line-height: 1.25em;
            font-size: .95em;
            padding: 10px;
            display: block;
            background:darken($light,20%);
        }
    }
}
@media (max-width:$xs){
    #inner-content{
        .sectionTitle{
            font-size:1.125em;
            text-align:center;
        }
        .slideTitle{
            font-size:2em;
            text-align:center;
        }
        margin-top:20px;
        .slideHolder{
            padding: 0 10px 50px 10px;
        }
    }
}