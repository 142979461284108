#returnMessage{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	@include transparentBG($basic,0.85);
	z-index:9999;
    .logoWrap{
        max-width:none;
        margin:-25px;
        padding:10px;
        background:$light;
        text-align:center;
        height:84px;
        margin-bottom:13px;
        .logo{
            max-height:100%;
            height:auto;
            margin:0 auto;
        }
    }
    .message{
        width:100%;
        position:absolute;
        left:0;
        top:-30%;
        margin:0;
        @include transparentBG($secondary,0.75);
        padding:25px 25px 45px;
        @include boxShadow;
        @media all and (max-width: $xs){
            top:0!important;
        }
    }
    h4{
        @include headerFont;
        font-size: 2.8em;
        font-weight: 400;
        text-align:center;
        color: #fff;
        margin:10px 0 10px 0;
        text-transform: uppercase;
        @media all and (max-width: $xs){
            font-size:2em;
        }
    }
    p {
        text-align:center;
        margin:0 20px;
        color:#fff;
        font-size: 1.2em;
    }
    em{
        color:$primary!important;
    }
   
    .continue{
        display:block;
        width:100px;
        padding:0;
        margin:25px auto 0 auto;
        text-transform: uppercase;
        color: $light;
        font-weight:bold;
        cursor: $cursor;
        span{
            @include btnStyle;
            background: $primary;
            }
//        
//        &:hover{
//            span{
//                color:darken($light,10%)
//            }
//            span.glyphicon{
//                color:$light;
//            }
//        }
    }
}
