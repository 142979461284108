#searchModeWrap{
    @include fixed;
    @include fillSpace;
    @include clearfix;
    @include transition(.5s);
    left:auto;
    right:-100%;
    z-index: 999;
    .searchMode &{
        right:0;
        display: block;
        .searchResultsPanel{
            right: 0px;
            .closeBtn{
                display:none;
            }
        }
    }
    .slide{
        min-height:100%;
    }
    .videoWrapper{
        //width:36%;
    }
    .slideSide{
        padding-right:40px;
        display:block!important;
    }
    .videoWrapper{
        .imgWrap{
            display:block!important;
        }
    }
    .textCopy{
        padding-right:40px;
    }
    //Area to hold slide
    .searchSlideHolder{
        width: 70%;
        position: absolute;
        top: 75px;
        left: 0px;
        height: calc(100% - 75px);
        overflow: auto;
        @media all and (max-width:$xs){
            
        }
        .slide{
            background:$light;
            @include clearfix;
            padding: 25px 15px 30px 75px;
            &::before{
                @include pseudoContent;
                width: 100%;
                @include absolute;
                height: $progress;
            }
            
            #learnMoreWrapper{
                display: none !important;
            }
            .progNum{display: none;}
            .content{
                @include clearfix;
            }
        }
        .timer{
            background: $secondary;
        }
    }
    
    //Area that holds search results
    .searchResultsPanel{
        width: 30%;
        position: absolute;
        right: -30%;
        top: 0px;
        @include transparentBG($light,.9);
        height: 100%;
        overflow: auto;
        @include transition();
        padding-top: 15px;
        @include boxShadow();
        @media all and (max-width:$md){
            width:100%;
        }
    }
}

.ezsearch-summary{
   // text-align: center;
    color: $dark;
    padding:0px 25px;
    font-size: 1.1em;
    margin-bottom:5px;
    @media all and (max-width:$md){
        padding: 0px 20px 0 45px;
    }
    strong{
        font-family: $primaryFont;
        font-weight: 700;
    }
    p{
        @include zeroMargPad();
        
    }
}

.ezsearch-result-count{
    color: $dark;
    font-style: italic;
    font-size: .85em;
    padding:0px 25px;
    @media all and (max-width:$md){
        padding: 0px 20px 0 45px;
    }
    p{
        @include zeroMargPad();
    }
    a{
        &:hover{
            color: $link;
            text-decoration: underline;
        }
    }
    
}

.ezsearch-results{
    padding-top: 20px;
}
.ezsearch-result{
    padding: 18px 25px;
    position: relative;
    overflow: hidden;
    border-bottom:1px solid darken($grey,20%);
    @media all and (max-width:$md){
        padding: 20px 20px 20px 45px;
    }
    .locked{
        .slideTitle,.topic,.preview{opacity: .5;}
        position: relative;
        &:hover{
            cursor: not-allowed;
            background: transparent;
        }
        .lockedMsg{
            display: none;
            @include absolute;
            width: 100%;
            top: 25px;
            background: $dark;
            color: $light;
            font-weight: 700;
            padding: 15px;
            text-align: center;
            font-size: 1.1em;
            @include rounded(6px);
        }
    }
    .resultContent{
        position: relative;
        left: 0px;
        @include transition($speed: .3s);
    }
   &.clicked{
       .resultContent{
            left: -90px;
       }
        .viewOptions{
            right: 0px;
        }
   }
    &:hover{
        cursor: pointer;
        @include transparentBG($light, .05);
    }
    &.viewing{
        /* background: $light;
        a{
            color: $primary;
            border-bottom: 2px solid $light;
        }
        .preview{
            color: $primary;
        }
        .topic{
            background: transparent;
            color: $primary;
        }
        .view{
            background:$primary;
        } */
        
    }
    .slideTitle{
        font-family:$primaryFont;
        font-weight: 400;
        text-transform: uppercase;
        color: $primary;
        font-size: 1.5em;
        padding: 0px 0px 7px;
    }
    a{
        color: $primary;
        display: inline-block;
        text-decoration:none;
        font-weight: 700;
    }
    .preview{
        color: $dark;
        @include zeroMargPad();
        font-size: 1.125em;
        margin-bottom: 10px;
    }
    .topic{
        font-family: $primaryFont;
        color: $dark;
        font-size: .8em;
        text-transform: uppercase;
    }
}
.viewOptions{
    width: 60px;
    height: 100%;
    margin: 10px auto;
    position: absolute;
    @include transition();
    right: -60px;
    top: -10px;
    border:2px solid $light;
    .view{
        text-align: center;
//        border-bottom: 2px solid $primary;
        height: 48.5%;
        background: $dark;
        padding-top: 20px;
        @include borderBox;
        margin-bottom: 3%;
        &:last-child{
            margin-bottom: 0%;
            border-bottom: 0px;
            height: 49%;
        }
        &:hover{
            background: $primary;
            cursor: pointer;
            p{
                font-size: .85em;
            }
            .icon{
                font-size: 0em;
                margin-top: -65px;
            }
        }
        p{
            @include zeroMargPad;
            @include transition();
            font-family: $primaryFont;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0em;
            margin-top: -20px;
            color: $light;
            
        }
        .icon{
            @include transition();
            color: $light; 
            font-size: 2em;
            line-height: 1em;
        }
    }
}
.ezsearch-pager{
    text-align: center;
    background: $basic;
    padding: 5px;
    color: $light;
    p{
        margin: 5px 0px;
    }
    a{&:hover{text-decoration: underline !important;}}
}

.ezsearch-no-results{
    background: $contrast;
    color: $light;
    font-weight: 700;
    font-family: $primaryFont;
    padding: 3px 5px;
    margin: 0px 25px;
    text-align: center;
    p{
        @include zeroMargPad;
    }
    strong{
        text-decoration: underline;
        font-size: 1.1em;
    }
}
.searchBtn{
    background: $alt;
    color: $light;
    z-index: 301;
    text-align: center;
    @include borderBox;
    @include transition();
    @media all and (max-width:$md){
        left:0;
    }
    #searchModeWrap &{
        display: block;
        position: fixed;
        right: 30%;
        top: 10px;
        width: 55px;
        height: 65px;
        @include transition();
        padding-top: 10px;
        background: $primary;
        @media all and (max-width:$md){
            position: absolute;
            width:40px;
        }
        .icon{
            color: $light;
            font-size: 1.4em;
            line-height: 1em;
            padding-top: 10px;
            display: inline-block;
        }
    }
    .searchMode &{
        display:block;
        .icon{
            &::before{
                content:'i';
            }
        }
    }
    &:hover{
        cursor: pointer;
    }
    
}
.searchMode{
    #searchModeWrap{
        .searchBtn{
            display:block;
        }
    }
}
#searchModeWrap{
    .searchBtn{
        display:none;
    }
}
.inSection{
    .searchBtn{
         right: -50px;
    }
    .alertBarContainer{
        display: none;
    }
}


.searchArea{
    width: 90%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 30px;
    @media all and (max-width:$md){
        padding-bottom:10px;
    }
}
.ezsearch-form{
    @media all and (max-width:$md){
        text-align:right;
    }
    .searchField{
        width: 85%;
        height: 45px;
        padding: 5px 10px;
        font-size: 1.2em;
        font-family: $primaryFont;
        font-weight: 400;
        color: $dark;
        @media all and (max-width:$md){
            width:calc(100% - 28px);    
        }
    }
    .btn{
        @include iconFont;
        width: 15%;
        position: absolute;
        right: 0px;
        top: 0px;
        height: 45px;
        border: 0px;
        background: $secondary;
        color: $light;
        font-size: 1.5em;
        &:hover{
            background: darken($secondary, 7%);
        }
    }
}

.summaryWrap{
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.resultsBtn{
    background: $secondary;
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 3px 10px 3px 35px;
    color: $light;
    text-transform: uppercase;
    font-family: $primaryFont;
    font-weight: 700;
    font-size: .9em;
    .icon{
        margin-right: 5px;
        font-size: 1.5em;
        position: absolute;
        bottom: -5px;
        left: 7px;
    }
}

@media (max-width:$lg){

}
@media (max-width:$md){
   .searchResultsContent{
        margin-top:20px;
    }
    .ezsearch-results{
        padding-top:0;
    }
}
@media (max-width:$sm){
    
    .ezsearch-result{
        padding:20px 45px;
    }
    .ezsearch-result-count{
        padding:0 45px;
    }
}
@media (max-width:$xs){
    .ezsearch-summary{
        padding:0 20px;
    }
    .ezsearch-result{
        padding:20px 20px;
    }
    .ezsearch-result-count{
        padding:0 20px;
    }
}