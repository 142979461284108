.term{
    text-decoration:underline;
    border-bottom: 1px solid currentColor;
    position: relative;
    display: inline-block;
    @include transition();
  //  padding: 3px 20px 0px 2px;
    &.open{
        background: $light;
        color: $primary;
        text-decoration: none;
        padding: 3px 3px 0px 3px;
        
        &::before{
            @include iconFont;
            content: 'i';
            font-size: .6em;
           margin-right: 3px;
        }
    }
    &:hover{
        cursor: pointer;
        background: $light;
        color: $primary;
    }
    &.right{
        .termBbl {
            left: auto;
            right: -10px;
        }
        .termArrow{
            left: auto;
            right: 35px;
        }
    }
    
    &.bottom{
        .termBbl {
            top: auto !important;
            
        }
        .termArrow{
             border-width: 11px;
            border-color: transparent;
            border-style: solid;
            bottom: auto;
            top: -11px;
            left: 35px;
            margin-left: -11px;
            border-bottom-color: #999;
            border-top-width: 0;
            position: absolute;
            display: block;
            width: 0;
            height: 0;
    
            &::after{
               position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                content: "";
                border-width: 10px;
                 bottom: auto;
                top: 1px;
                margin-left: -10px;
                content: " ";
                border-bottom-color: #f7f7f7;
                border-top-width: 0;
            }
        }
    }
}

.termBbl {
  position: absolute;
    color: $basic;
  top: 0;
  left: -10px;
  z-index: 1010;
  width: 325px;
  padding: 1px;
  text-align: left;
  white-space: normal;
opacity: 0;
  background-color: $light;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
 @include boxShadow($x: 0px, $y: 5px, $blur: 10px, $spread: 0px, $color: rgba(0,0,0,.2));
  background-clip: padding-box;
    margin-top: -10px;
    

}


.termArrow{
        border-width: 11px;
    border-color: transparent;
    border-style: solid;
    bottom: -11px;
    left: 35px;
    margin-left: -11px;
    border-top-color: #999;
    border-bottom-width: 0;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    
    &::after{
       position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        content: "";
        border-width: 10px;
         bottom: 1px;
        margin-left: -10px;
        content: " ";
        border-top-color: #fff;
        border-bottom-width: 0;
    }
}


.termTitle {
  padding: 6px 14px !important;
  margin: 0px !important;
  font-size:1.05em !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.termContent {
    padding: 5px 14px 8px !important;
    font-size: .9em !important;
    line-height: 1.5em;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}


@media (max-width: $xs){
  .termBbl {
    width: 250px;
  }
}

@media (max-width: $xxs){
    .termBbl {
    width: 200px;
  }
}

