.accordionButtonWrapper{
    border-left: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
        @include zeroMargPad;
        &:first-child{
            margin-top: 20px !important;
        }
        &.open{
            .accordionButton{
               // background: $secondary;
                cursor: pointer;
                color: $light;
                &:before{
                    @include rotate( $deg: 180deg);
                }
            }
        }
    p{
        border-left: 0px !important;
        padding-left: 0px !important;
    }
    }

    .accordionButton{
         font-size: 1.3em;
        font-family: $primaryFont;
        background: $primary;
        font-weight: 700;
        outline: none !important;
        color: $light;
        padding: 10px 0 12px 50px;
        display: block;
        position: relative;
        margin-bottom: 0px;
        border: 3px solid $light;
        &:hover{
            cursor: pointer;
            background: $primary;
            color: $light;
            &:before{
                color: $light;
            }
            
        }
        &:before{
            @include pseudoContent;
            @include bottomArrow($size: 8px, $color: $light, $top: 20px, $left: 20px);
            @include rotate( $deg: 0deg);
            @include transition(.3s);
        }
        
    }
    .accordionWrapper{
        display: none;
        padding: 13px 20px 25px;
        margin: 0 3px 3px 3px;
        background: $light;
        border:1px solid $primary;
        p{
            border-left: 0px !important;
            padding-left: 0px !important;
        }
        h3{
            font-size: 1.15em;
            margin-bottom: 10px;
        }
    }


@media (max-width: $xs){
    .accordionButton{
        font-size: 1em;
        padding: 10px 0 12px 40px;
    }
    .accordionButtonWrapper{
        margin: 0px -20px 0px -20px !important;
    }
    .accordionWrapper{
        margin: 0px -20px 3px -20px !important;
    }
}