.masterQuizSlide,.surveySlide{
	h3{
		display:block;
	}
	.questionBlock{
		@include clearfix;
		margin:25px 0;
		@include transition($speed:.4s);
		&:hover{
			
		}
	}
	.question{
		h3{
			padding:15px;
			line-height: 1.25em;
			color:$basic;
			border:2px solid $dark;
			margin-bottom:0;
			font-weight:normal;
			font-size:1.35em;
			background:$light;
			span{
				display: block;
				float: left;
				padding: 15px;
				background-color:$dark;
				color: $light;
				margin: -15px 10px 0 -15px;
			}
		}
	}
	.answer,.Answer{
		div{
			color:$basic;
			margin:0 0 0 0!important;
			padding:8px 16px;
			@include transition($speed:.3s);
			cursor:pointer;
			background:$light;
			
			@include clearfix;
			&:before{
				content:'';
				display:block;
				float:left;
				width:23px;
				height:23px;
				@include rounded(50%);
				border:1px solid $basic;
				background:$light;
				margin-right:0;
				margin-top:0;
				font-family: $iconFont;
				font-size:1.25em;
				line-height:.9em;
			}
			&:hover{
				 background:lighten($secondary,30%);
				 span{
				 	
				 }
			}
			&.selected{
				background:$primary!important;
				&:before{
					content:'c';
					background:$light;
					color:black;
					line-height: .6em;
					font-size:1.85em;
					border:0;
				}
				label{
					color:$primary;
					font-weight:bold;
				}
			}
			label{
				width:100%;
				display:block;
				position: relative;
				@include clearfix;
				cursor: pointer;
			}
			input{
				float:none;
				margin:0 5px 0 0!important;
				position: absolute;
  				z-index: -1;
			}
			textarea{
				color:$basic;
			}
			span{
				margin-left: 31px;
				display: block;
				margin-top: 0;
				color:$basic;
				cursor: pointer;
			}
		}
	}
	#dc1,#dc2,#dc3,#dc4,#dc5{
		background:lighten($primary,10%);
		padding:10px;
		color:$light;
		@include transition($speed:.4s);
		display:block!important;
		@include opacity(100,1);
		visibility: visible !important;
		&.hidden{
			@include opacity(0,0);
			visibility: visible !important;
			display:block!important;
			height:0;
			overflow:hidden;
			padding:0;
			textarea{
				height:0;
			}
		}
		textarea{
			width:100%;
			height:100px;
			color:$basic;
		}
	}
	&.masterQuizSlide{
		&.completed{
			.questionBlock{
				display:none;
			}
			.reviewText,.reveiwText{
				display:none;
			}
		}
		&.graded{
			.gradedMessage{
				display:block;
				p{
					margin-right:125px;
					@media all and (max-width: $xs){
						margin-right:0;
					}
				}
			}
			.retakeMessage{
				display:none;
			}
			.backToMenuBtn{
				cursor: pointer ;
				margin: -67px auto 10px auto;
				text-align: center;
				display: block;
				@include rounded(0);
				padding:10px 25px;
				background:$primary;
				color:$light;
				text-transform:uppercase;
				font-size:1.125em;
		 		border: 1px solid white;
		 		float:right;
		 		@include transition;
		 		&:hover{
		 			background:lighten($primary,10%);
		 		}
			}
			.questionBlock{
				display:none!important;
				&.review{
					display:block!important;
				}
				input{
					display:none;
				}
				div{
					&:before{
						display:none;
					}
					label{
						span{
							cursor: default;
						}
					}
				}
				div.wrong{
					color: darken($accent,10%);
					font-weight:bold;
					cursor: default;
					&:before{
						display:block;
						font-family: $iconFont;
						content: "i";
						background:transparent!important;
						border:0!important;
						color:red;
						font-size:1.5em;
						margin-top:3px;
						margin-left: -11px;
					}
					label{
						span{
							color:red!important;
							cursor: default;
						}
						
					}
				}
				.answer{
					div{
						cursor: default;
						&:hover{
							margin:0!important;
							background:$light!important;
						}
					}
				}
			}
			#survey-submit.btn,#quiz-submit{
				display:none;
			}
		}
		&.passed{
			.questionBlock{
				div.CA{
					font-weight:bold;
					cursor: default;
					&:before{
						display:block!important;
						font-family: $iconFont;
						content: "c";
						background:transparent!important;
						border:0!important;
						color:$correct;
						font-size:1.5em;
						margin-top:3px;
						margin-left: -11px;
					}
					label{
						span{
							color:$correct!important;
							cursor: default;
						}
						
					}
				}
			}
		}
		.gradedMessage{
			display:none;
		}
		.answer,.Answer{
			div{
				/* float:none;
				width:100%;
				text-align:left;
				background:transparent;
				color:$basic;
				border-bottom:2px solid $light; */
				/* &:hover{
					background:lighten($secondary,10%);
					color:darken($primary,35%);
				} */
			}
		}
	}
	&.surveySlide{
		.questionBlock{
			h3{
				@include clearfix;
				border-bottom:0;
				background:$dark;
			}
			.answer{
				@include clearfix;
				border:2px solid black;
				border-top:0;
				background:$light;
				div{
					float:left;
					label{
						color:$basic;
					}
				}
			}
		}
	}
	#survey-submit.btn,#quiz-submit{
		margin: 10px auto;
		text-align: center;
		display: block;
		@include rounded(0);
		padding:10px 45px;
		background:$primary;
		color:$basic;
		text-transform:uppercase;
		font-size:1.125em;
 		border: 1px solid white;
 		@include transition;
 		&:hover{
 			background:$secondary;
 			color:$light;
 		}
	}
	.textCopy{
		width:100%!important;
		font-size:1em;
	}
}
.ie8{
	.questionBlock{
		margin:0!important;
		&:hover{
			margin:0!important;
		}
		border-bottom:2px solid lighten($dark,45%);
		&.error{
			background:lighten($accent,30%);
		}
	}
}