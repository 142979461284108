#homeScreen{
    width:100%;
    height:calc(100% - 130px - 45px);
    position: fixed;
    top:$headerHeight;
    left:0;
    overflow-x:hidden;
}
.inSection{
    #homeScreen{
        height:auto;
        z-index: 999;
    }
}
.slideOverlay{
    @include absolute;
    @include fillSpace;
}
.overlay{
    @include transparentBG($accent, .75);
    position: absolute;
    height: 100%;
    top: 0px;
    
    &.left{
        left: 0px;
    }
    &.right{
        right: 0px;
    }
}
.homeBtn{
    @include fixed;
    left:-100px;
    top:155px;
    cursor:pointer;
    @include transition(.5s);
    z-index: 999;
    &:hover{
        .icon-home{
            @include opacity(0,0);
        }
        .icon-arrow-left{
            @include opacity(100,1);
        }
    }
    &.inside{
        left:42px;
    }
    span{
        @include transition;
        font-size:2.5em;
        color:$primary;
        @include absolute;
    }
    .icon-home{
        
    }
    .icon-arrow-left{
        @include opacity(0,0);
    }
}
.ie9 .homeBtn{
    .icon-home{
        @include opacity(100,1);
    }
    .icon-arrow-left{
        @include opacity(0,0);
        display:none!important;
    }
}
@media (max-width:$lg){

}
@media (max-width:$md){
   
}
@media (max-width:$sm){
    #homeScreen{
        overflow-y:auto!important;
    }
    .homeBtn.inside{
        top: auto;
        bottom: 43px;
        left: 50%;
        margin-left: -15px;
        span{
            font-size:2.125em;
            top:5px;
        }
    }
}
@media (max-width:$xs){
    #homeScreen{
        position: relative!important;
        top: auto;
        left: auto;
        height: auto;
    }
}