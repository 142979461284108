/**MIXINS**/
@mixin header {
  color: $primary;
  font-family: $primaryFont;
  text-transform: uppercase;
}

@mixin titleFont {
  @include header;
  font-weight: 400;
}

@mixin mainHeadFont {
  @include header;
  font-weight: 700;
}

@mixin bodyCopy {
  color: $basic;
  font-family: $basicFont;
  font-size: 1.25em;
  line-height: 1.55em;
}

@mixin maxWidth {
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0px;
  @include borderBox;
  @include clearfix;
}
@mixin innerMaxWidth {
  max-width: $maxWidth - 30;
  margin: 0 auto;
  padding: 0px 15px;
}

@mixin btnStyle {
  background: $secondary;
  text-decoration: none;
  font-size: 0.9em;
  font-family: $primaryFont;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: $light;
  text-transform: uppercase;
  &:hover {
    background: $contrast;
    color: $light;
  }
}

@mixin inputStyle {
  padding-left: 8px;
  border: 0px;
  background-color: transparent;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  color: #666;
  font-size: 12px;
  font-weight: normal;
  float: left;
  height: 26px;
  outline: none;
}

@mixin btnBorder {
  border: 7px solid $light;
}

@mixin iconFont {
  font-family: $iconFont !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin olStyle {
  margin-top: 5px;
  list-style-type: none;
  counter-reset: li; /* Initiate a counter */
  margin-left: 0; /* Remove the default left margin */
  padding-left: 0; /* Remove the default left padding */
  li {
    position: relative; /* Create a positioning context */
    margin-left: 2em; /* Give each list item a left margin to make room for the numbers */
    list-style: none; /* Disable the normal item numbering */

    &:before {
      content: counter(li); /* Use the counter as content */
      counter-increment: li; /* Increment the counter by 1 */
      /* Position and style the number */
      position: absolute;
      top: 3px;
      left: -2em;
      @include borderBox;
      width: 1.8em;
      /* Some space between the number and the content in browsers that support
               generated content but not positioning it (Camino 2 is one example) */
      margin-right: 8px;
      padding: 2px;
      color: $light;
      background: $secondary;
      font-weight: bold;
      text-align: center;
      font-size: 0.9em;
      @include rounded(50%);
    }
  }
}

@mixin ulStyle {
  list-style-type: none;
  @include zeroMargPad;
  margin-left: 15px;
  margin-bottom: 5px;
  li {
    @include zeroMargPad;
    padding-left: 15px;
    margin-bottom: 5px;
    position: relative;

    &:before {
      @include iconFont;
      @include absolute;
      top: 6px;
      color: $secondary;
      @include pseudoContent;
      content: "f";
      font-size: 0.75em;
    }
  }
}

@mixin reformatList {
  list-style-type: none;
  @include zeroMargPad;
  @include clearfix;
}

@mixin darkBG {
  @include transparentBG($basic, 0.75);
}

/***ADA MIXINS***/
@mixin adaHeaderFont {
  color: $primary;
  font-family: $primaryFont;
}
@mixin adaBtns {
  text-align: center;
  color: $light;
  background-color: $dark;
  padding: 5px 15px;
  margin: 10px auto;
  font-family: $primaryFont;
  text-decoration: none;
  font-weight: 600;
  &:focus {
    background: $primary !important;
  }
}
@mixin adaBtnHover {
  text-decoration: underline;
}
@mixin adaSchoolLogo {
  padding: 20px 25px;
  //background: url('../images/uvu-logo.svg') no-repeat left center;
  //background-size: 72px 58px;
  font-family: $primaryFont;
  padding: 8px 0px 5px 97px;
  float: left;
  margin: 8px 0 0 0;
  padding: 0;

  //background:url(../images/uvu-logo.svg) no-repeat;
}

@mixin dashedBorderRight($color) {
  border-right: 1px dashed $color;
}
@mixin dashedBorderLeft($color) {
  border-left: 1px dashed $color;
}
@mixin dashedBorderTop($color) {
  border-top: 1px dashed $color;
}
@mixin dashedBorderBottom($color) {
  border-bottom: 1px dashed $color;
}
