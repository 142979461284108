.slide,#helpCarousel{
    p{
        @include zeroMargPad;
        margin-bottom: 15px;
    }
    .intro{
        font-family: $primaryFont;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.45em;
        line-height: 1.7em;
    }
    .sub-head{
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 1.45em;
        line-height: 1.7em;
    }
    h2{
        @include mainHeadFont;
        color:black;
        font-size: 1.7em;
        margin: 35px 0 20px 0
    }
    h3{
        background:$dark;
        color:$light;
        padding:5px 10px;
        margin:15px 0 15px 0;
        font-weight:normal;
        display:inline-block;
        border-left: 6px solid $primary;
    }
    h4{
        margin: 10px 0 5px 0;
        @include mainHeadFont;
        font-size:1.5em;
        line-height: 1em;
        text-transform:none;
        border-left:0;
    }
    
    .blockText{
        background: $secondary;
        display: block;
        padding: 15px 20px;
        color: $light;
        line-height: 1.6em;
        font-style: italic;
        margin-top: 45px;
    }
}