header{
    @include fixed;
    width: 100%;
    padding: 0 80px;
    @include transition(.5s);
    z-index: 999;
    @include transparentBG(#ddd,0);
}
.inSection{
    header{
        background:#E2E2E2;
        padding:0 80px 2px 80px;
    }
}

.logoWrap{
    max-width: 300px;
    float:right;
    padding-top:20px;
    h1{
        @include titleFont;
        font-weight:100;
        font-size: 1.55em;
        color:$secondary;
        margin-top:5px;
    }
}
.logo{
    max-width: 100%;
    height: auto;
    &:hover{
        cursor: $cursor;
    }
}
.ie9 .student{
    float:right!important;
 }
.student{
    text-align: right;
    font-size: 1.125em;
    float: right;
    height: 110px;
    margin-right: 50px;
    padding-right: 50px;
    padding-top:10px;
    @include dashedBorderRight($primary);
    display:table;
    h3{
        display:table-cell;
        vertical-align:middle;
        height:100%;
        @include zeroMargPad;
        @include titleFont;
        color:$secondary;
        text-transform:none;
        font-weight:100;
        span{
            font-weight: 600;
            color:$primary;
        }
    }

 /* .logout{
        //text-transform: uppercase;
        text-decoration: underline !important;
        font-size: .8em;
        font-weight: 400;
        color: $primary;
        margin:10px 0 10px 10px;
        display:block;
    } */
}

.cloneNavWrap{
    padding: 30px 0px 0px;
}
#clone-nav{
    @include reformatList;
    .titleWrap,.bgImg,.imgCaption{
        display: none;
    }
    .button{
        float: left;
        font-size: .62em;
        padding-bottom: 15px;
        &.active{
            a{
            color: $secondary;
                &:hover{
                    color: $primary;
                }
            }
        }
        &.completed{
            a{
            color: $primary;
                &:before{
                    @include iconFont;
                    content: 'c';
                    @include absolute;
                    font-size: 1.8em;
                    left: -2px;
                    top: 2px;
                }
            }
        }
    }
    a{
        display: block;
        color: $alt;
        position: relative;
        text-transform: uppercase;
        padding-left: 15px;
    }
    h2{
        font-family: $primaryFont;
        font-weight: 400;
    }
}


@media (max-width:$lg){

}
@media (max-width:$md){
    header{
        padding:0 20px
    }
    .student {
      display: none;
        h3{

            display:none;

        }

    }
}
@media (max-width:$sm){
    .logoWrap{
        float: none;
        text-align: center;
        max-width: none;
    }
}
@media (max-width:$xs){
    header{
        position: relative;
        top: auto;
        left: auto;
    }
    .inSection{
        header{
            .logoWrap{
                padding-top:10px;
            }
            h1{
                font-size:1em;
            }
        }
    }
    .logoWrap{
        margin: 0 auto;
        img{
            width:140px;
        }

    }
}
