.alertBar{
    width: 450px;
    margin: 0;
    padding-right:80px;
    @include clearfix;
    position: relative;
    display: table;
    height: $footerHeight;
    float:right;
    background:$primary;
    .alert,.help{
        display: table-cell;
        width: 20%;
        height: 100%;
        background: $primary;
        text-align: center;
        vertical-align: middle;
        color: $light;
        @include transition;

        .icon{
            font-size: 1.5em;
            line-height: 1em;
        }
        &:hover{
            background: $light;
            cursor: $cursor;
            color: $primary;
        }
        &.active{
//            border-color: darken($primary, 5%);
//            background: $primary;
            color: $secondary;
           &:hover{
                background: $secondary;
                color: $primary;
                border-color: darken($secondary, 5%);
            }
         &.announcements{
             padding-top: 2px;
                .icon{
                    font-size: 2.5em;
                    &:before{

                    }
                }
            }
        }
        &.clicked{
         background: $dark;
                color: $primary;
                border-color: darken($secondary, 5%);

        }
    }
    .notify{
        font-family: $primaryFont;
        font-weight: 700;
        line-height: 1em;
        display: block;
        font-size: .9em;
        margin-bottom: -12px;

    }
    .alertName{
        display: none;
        @include absolute;
        background: $secondary;
        height: 35px;
        top: -35px;
        width: 100%;
        text-align: center;
        @include borderBox;
        padding-top: 7px;
        text-transform: uppercase;
        color: $primary;
        font-family: $primaryFont;
    }
    .resources{
        padding-top: 2px;
        .icon{

        }
    }
}
.alertName{
    display:none;
}
.searchMode{
    .alertBar{
        display: none;
    }
}

//ALERT CONTENT AREAS//

#alertContentWrap{
    position: fixed;
    bottom: $footerHeight;
    background:$light;
    padding:0px;
    @include borderBox;
    z-index: 230;
    width: 100%;
    height:calc(100% - 180px);
    display:none;
    overflow:auto!important;
    @media all and (max-width: $xs){
        height: calc(100% - 35px);
        bottom: 35px;
    }
    &.open{

    }
    .alertContent{
        display: none;
        padding: 0 0 75px 80px;
        @media all and (max-width: $xs){
            padding-left:20px;
            padding-right:20px ;
            padding: 0 20px 75px 20px;
        }
    }
    h1{
        font-family: $primaryFont;
        font-weight: 400;
        color: $light;
        font-size: 1.75em;
        padding: 10px 40px;
        margin-bottom: 15px;
        text-transform: uppercase;
        background: $dark;
        text-align: left;
        @media all and (max-width: $xs){
            font-size:1.5em;
            padding: 10px 10px 10px 80px;
        }
    }

    #checklist{
        .checkContent{
            @media all and (max-width: $xs){
                margin-right:20px;
            }
        }
        .list{
           list-style:none;
           padding-left:35px;
        }
        li{
            font-family: $basicFont;
            border-bottom: 1px solid $accent;
            padding:15px 0 15px 30px;
            color:$basic;
            text-transform: uppercase;
            position: relative;
            @include clearfix;
            &:before{
                @include pseudoContent;
                height: 15px;
                width: 15px;
                border: 2px solid $basic;
                @include absolute;
                top: 20px;
                left: 5px;
                line-height: .5em;
                font-size: 1.2em;
                text-align: center;
                text-transform: none;
            }
            a{
                @include btnStyle;
                background:$primary;
                color:$light;
                padding:5px 8px;
                float:left;
                margin:10px 0;
                text-transform: none;
                @include rounded(4px);
                text-decoration:none!important;
                &:hover{
                    background:darken($primary,10%);
                    text-decoration:none!important;
                }
                &:before{
                    font-family:$iconFont;
                    content:'b';
                    display:block;
                    float:left;
                    margin-right:5px;
                }
            }
            &.checked{
                &:before{
                    content:'c';
                    font-family: $iconFont !important;
                    text-align: center;
                }
                h3{
                    cursor:default;
                }
            }
        }
        .instruction{
            font-family: $basicFont;
            font-size: 1.25em;
            line-height: 1.7em;
            background: $primary;
            padding: 5px 10px 5px 40px;
            color:$light;
            @media all and (max-width: $xs){
                font-size: 1.2em;
                line-height: 1.35em;
            }
            p{
                @include zeroMargPad;
            }
        }
        h3{
            font-family:$basicFont;
            font-size: 1.3em;
            font-weight: 400;
            text-transform:none;
            padding-bottom:5px;
            margin-left: -26px;
            padding-left: 26px;
            cursor:pointer;
            position: relative;
        }
        .due{
            font-weight: 800;
            text-transform: uppercase;
            font-size: .9em;
            font-family: $basicFont;
            display:block;
            margin: 5px 0;
            clear:both;
        }
        a{
          text-decoration: none;
            &:hover{
                text-decoration: underline !important;
            }
        }
    }

    #announcements{
        .announcement{
            border-bottom: 1px solid $accent;
            padding: 20px 0px;
            margin-right:40px;
            font-family: $secondaryFont;
            font-size:1.125em;
            @media all and (max-width: $xs){
                margin-right:20px;
            }
            &.new{
                background-color: darken($light,20%);
                /* h2{
                    font-family: $secondaryFont;
                    color: $light;
                }
                p{
                    font-family:$basicFont;
                    color: $basic;
                } */
            }
            h2{
                font-family: $secondaryFont;
                font-size: 1.3em;
                padding: 3px 20px;
                color: $basic;
                &:before{
                    font-family:$iconFont;
                    content: 'd';
                    margin-left: -10px;
                    margin-right: 10px;
                    text-transform:none;
                    color:$basic
                }
            }
            p{
                @include zeroMargPad;
                padding: 5px 20px 0px 40px;
                color: $basic;
                line-height: 1.7em;
                @media all and (max-width: $xs){
                    padding:5px;
                    line-height:1.35em;
                }
            }
        }
    }
    #account{
        .accountContent{
            @include clearfix;
            margin-left:40px;
            @media all and (max-width: $xs){
                margin-left:10px;
            }
            h2{
                font-family:$secondaryFont;
                font-size:1.5em;
                font-weight:400;
                color:lighten($secondary,25%);
                float:left;
                margin-right:20px;
                @media all and (max-width: $xs){
                    margin: 0 20px 20px 0;
                }
                span{
                    font-weight:700;
                    color:$basic;
                }
            }
            a{
                @include btnStyle;
                margin:0;
                display:block;
                float:left;
            }
        }
    }
    #resources{
        .slide{
            margin-top:0;
        }
        @media all and (max-width: $xs){
            padding: 0 0 75px 0;
        }
        h1{
            @media all and (max-width: $xs){
                padding: 10px 10px 10px 94px;
            }
        }
        h3{
            font-family:$secondaryFont;
            margin:20px 0 10px 0;
            font-size: 1.5em;
        }
        h4{
            display:none;
        }
        .slideTitle{
            display:none;
        }
        .textCopy{
            padding:0 60px 0 0;
            @media all and (max-width: $xs){
                padding:0;
            }
        }
    }
    .closeBtn{
        display: none;
        background: $primary;
        color: $light;
        width: 80px;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5em;
        padding: 17px 20px 8px 20px;
        text-align: center;
        @media all and (max-width: $xs){
            font-size: 1.3em;
            padding: 10px 20px 8px 20px;
        }
        &:hover{
            cursor: pointer;
            color: $secondary;
        }
        &.show{
            display: block;
        }
    }
}
#allowChecked{
    display: none;
    position: fixed;
    top: 20%;
    left: 50%;
    width: 300px;
    margin: 0 0 0 -150px;
    background:$primary;
    padding: 20px;
    z-index: 9999;
    color: $basic;
    text-align: center;
    h1{
        color: $light;
        background: transparent;
        margin-bottom: 15px;
        font-size: 2.5em;
        font-family:$secondaryFont;
    }
    span{
        background:$dark;
        color: $light;
        padding: 5px 10px;
        cursor: pointer;
        @include rounded(4px);
        &:hover{
            color:$light;
            background:$basic;
        }
    }
    p{
        color:$light;
    }
}
#hiddenOverlay{
    display: none;
    @include fixed;
    @include fillSpace;
    z-index: 210;
    @include transparentBG(#000, .85);

}

#wrapper.noOverflow{overflow: hidden; }
@media (max-width:$lg){

}
@media (max-width:$md){

}
@media (max-width:$sm){
    .alertBar{
        padding-right:0;
        height:0;
        width:100%;
        .logout{
            width: 30%;
            display: block;
            float: left;
            padding: 10px 0;
            text-align:center;
        }
        .alert,.help{
            display: block;
            float: left;
            width:14%;
            padding: 7px 0;
        }
        .notify{
            font-weight:400;
            line-height:.3em;
            font-size:.8em;
        }
    }
    #alertContentWrap{
        height: calc(100% - 40px);
        bottom: 40px;
        z-index: 999;
        .alertContent{
            padding:0!important;
            h1{
                padding-left:90px;
            }
            .checkContent{
                margin-right:0!important;
                .instruction{
                    font-size:1em!important;
                }
            }
        }
        #checklist{
            .list{
                padding-left:0;
                padding:0 20px;
            }
        }
        #announcements{
            .announcement{
                margin-right:0;
                padding:20px;
            }
        }
        #resources{
            .slide{
                padding:20px;
            }
        }
    }
}
@media (max-width:$xs){
    #alertContentWrap{
        h1{
            padding: 6px 0px 7px 90px!important;
            margin-bottom:0!important;
        }
        #resources{
            h1{
                padding: 6px 0px 7px 90px!important;
            }
        }
    }
}
