.progressBar {
    background: $dark;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    height: 25px;
    width: 100%;
    z-index: 100;

    .sectionTitle {
        position: relative;
        @include titleFont;
        color: $primary;
        z-index: 2;
        font-size: 1.125em;
    }

    .progress {
        background: $primary;
        @include absolute;
        height: 100%;
        text-align: right;
        padding: 3px 10px;
        color: $light;

        &:after {}

        .available {
            display: none;
        }
    }
}

.timer {
    position: relative;
    height: 2px;
    @include transparentBG($accent, .2);

    .inner {
        @include absolute;
        height: 1px;
        background: $accent;
    }
}

.menuBtn {
    background: $light;
    @include header;
    color: $secondary;
    text-align: center;
    font-size: .9em;
    height: $progress;
    font-weight: 400;

    span {
        display: inline-block;
        padding-top: 2px;
    }

    &:hover {
        cursor: $cursor;
        color: $primary;
    }
}

@media (max-width:$sm) {
    .progressBar {
        height: 5px;
        bottom: 40px;

        .progress {
            span {
                display: none;
            }
        }
    }
}