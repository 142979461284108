#umbracoPreviewBadge{
    position: fixed!important;
    top: 0px !important;
    left:auto!important;
    width:100%!important;
    height:auto!important;
    background:#bacb0f!important;
    display:block!important;
    padding:0 4px 10px 4px;
    color:black;
    text-align:center;
    max-height:96px;
    overflow:auto;
//    &:before{
//        display:inline-block;
//        font-size:1.125em;
//        font-weight:bold;
//        text-transform:uppercase;
//        content:"PREVIEW MODE";
//        background:black;
//        color:#bacb0f;
//        padding:8px 15px;
//    }
    &:after{
        display:block;
        font-size:1em;
        margin-top:5px;
        content:"PREVIEW MODE: This is a preview, and should be used for content evaluation only. Some features and styles may not act/look exactly the way they do on live site."
    }
    &:hover{
        background:lighten(#bacb0f,10%)!important;
    }
//    &:first-of-type{
//        display:none!important;
//    }
    /* span{
        display:block!important;
    } */
}
$top: 35px;
.PreviewMode{
    header{
        top:$top;
    }
    .inSection{
        #inner-content{
            margin-top:189px;
        }
        header{
            top:$top;
        }
       .slide{
            display:none;
        }
        .slideHolder{
            .slide{
                display:block;
            }
        }
        .homeBtn.inside{
            top:190px;
        }
        .button.clicked{
            top:$top!important;
        } 
    }

}
@media (max-width:$md){
    #umbracoPreviewBadge{
        display:none!important;
    }
    .PreviewMode{
        header{
            top:0;
        }
        .inSection{
            #inner-content{
                margin-top:154px;
            }
            header{
                top:0;
            }
            .button.clicked{
                top:0!important;
            }
            .homeBtn.inside{
                top:auto!important;
            }
        }
    }
}
@media (max-width:$xs - 100){
    .PreviewMode{
        .inSection{
            header{
                //top:$top;
            }
            #inner-content{
                margin-top:20px;
            }
        }
    }
}