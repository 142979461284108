.videoWrapper {
  margin-bottom: 20px;
  .loading {
    display: block;
    color: $grey;
    font-size: 1.2em;
    font-family: $primaryFont;
    text-align: center;
    padding-top: 40px;
    text-transform: uppercase;
  }
}

#videoHolder,
.videoHolder {
  position: relative;
  padding-bottom: 75%;
  padding-top: 0;
  height: 0;
  border: 1px solid $secondary;
  &.wide {
    padding-bottom: 56%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.readBtn {
  background: $primary;
  padding: 8px 0px;
  text-align: center;
  @include titleFont;
  color: $light;
  &:hover {
    background: $contrast;
    cursor: pointer;
  }
}
