.login{
    &.errorPage{
        .errorMessage{
            h2,h3{
                display:block;
            }
            h2{
                color:$primary;
                line-height:1.125em;
            }
        }
    }
    &.queryString{
        header{
            top:14%;
        }
        .logoWrap{
            width:20%;
        }
        .student{
            width:70%;
            margin-right:5%;
        }
        .loginBorder{
            width:22%
        }
        .queryBuilder{
            display:block;
        }
    }
    header{
        @include fixed;
        top:48%;
        height:90px;
        margin-top:-65px;
        padding:0;
    }
    .logoWrap{
        width:50%;
        margin:0;
        padding-right:35px;
        padding-top:0;
        max-width:none;
        float:left;
        text-align:right;
    }
    .student{
        width: 50%;
        height: 100%;
        margin: 0;
        float: none;
        padding: 0;
        border-right: 0;
        text-align: left;
        display: table;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 0;
        justify-content: center;
        z-index: 1000;
        padding-left:20px;
        .titleArea,.newStudentWrapper{
            border-bottom: 1px dashed $primary;
            margin-left:-20px;
        }
        .titleArea,.newStudentWrapper,.returningStudentWrapper{
            padding:20px;
        }
        .returningStudentWrapper {
            margin-left:-20px;
            .utility a{
                margin-right:0;
            }
        }
        div{
            display:block;
        }
        h1{
            color:$primary;
            line-height:1.125em;
            &.error{
                padding-left:20px;
            }
        }
    }
    input,textarea{
        display: block;
        padding: 5px;
        min-width: 380px;
    }
    .fieldWrapper{
        float:none;
        width:100%;
        padding:0;
    }
    .formWrap:not(.login){
        margin-left:-20px;
        padding:20px;
        border-top:1px dashed $primary;
        border-bottom:1px dashed $primary;
    }
    .Submit button,.newStudentWrapper .account a{
        background:$primary;
        border:none;
        color:$light;
        border-radius:0;
        padding:5px 10px;
        cursor:pointer;
        margin-bottom:10px;
        &:hover,&:focus,&:focus-visible{
            background:$secondary;
        }
    }
    .account.forgotPass,.utility {
        width:auto;
        a{        
            margin-right:20px;
        }
        .forgotPass a{
            margin-right:0;
        }
    }
    #inner-content{
        display:block;
    }
    .loginBorder{
        @include fixed;
        width:50%;
        height:100%;
        @include dashedBorderRight($primary);
        z-index:-1;
    }

}
@media (max-width:$sm){
    .login{
        header{
            position: relative;
            top:auto;
            margin-top:20px;
        }
        .logoWrap{
            width:100%;
            float:none;
            text-align:center;
            padding-right:0;
            padding-bottom:15px;
            margin-bottom:20px;
            border-bottom: 1px dashed $primary;
        }
        .student{
            width:100%;
            float:none;
            text-align:center;
            display:block;
            position:relative;
            padding-top:60px;
            h1{
                margin-bottom: 20px;
            }
            .row{
                display: flex;
                align-content: center;
                justify-content: center;
            }
            .newStudentWrapper,.returningStudentWrapper,.titleArea,.login .formWrap:not(.login){
                margin-left:0;
            }
        }
        input,textarea{
            margin: 0 auto 20px auto;
        }
        .loginBorder{
            display:none;
        }
        .formWrap:not(.login) {
            border:none;
        }
    }
    
}

@media (max-width:$xs){
    .login {
        .student{
            padding-top: 20px;
        }
        input,textarea{
            min-width: 100%;
        }
    }
}

@media (max-height:650px){
    .login .student{
        justify-content: flex-start;
    }
}