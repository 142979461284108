@-ms-viewport {
  width: auto !important;
}

html {
  //Shows sidebar at all times
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  overflow-x: hidden;
}

.row {
  margin: 0px;
}
body {
  @include zeroMargPad;
  background: $light;
}

body:not([data-kaltura-client-partnerid=""])
  .playkit-pre-playback-play-overlay
  .playkit-pre-playback-play-button {
  transform: none !important;
}

img {
  border: 0;
}

a {
  outline: none;
  color: $link;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include zeroMargPad;
}

.clearfloat {
  clear: both;
}

#wrapper {
}

.maxWidth {
  @include maxWidth;
}
.fullInner {
  padding: 0px 15px;
}

#inner-content {
  display: none;
}

.adaBtn {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 50px;
  color: $primary;
  text-transform: uppercase;
  font-size: 0.8em;
  z-index: 250;
}
