.slide.quizSlide {
  .videoWrapper {
    #videoHolder,
    .videoHolder {
      padding-bottom: 56%;
    }
  }
}

.quizButtons {
  @include zeroMargPad;
  list-style: none;
  margin-top: 15px;
  width: auto;
  @include headerFont($weight: 600);
  li {
    @include headerFont($weight: 100);
    @include clearfix;
    padding: 20px;
    cursor: $cursor;
    color: $basic;
    margin-bottom: 10px;
    width: 100%;
    display: table;
    font-size: 1.25em;
    text-align: left;
    @include transition;
    &:hover {
      background: $primary;
      color: $light;
      .letter {
        color: $light !important;
      }
    }
  }
  p {
    float: right;
    padding: 13px;
    padding-right: 40px !important;
    margin: 0px !important;
    font-size: 1em;
    height: 100%;
    width: 80%;
    box-sizing: border-box;
    text-align: left;
    vertical-align: middle;
    display: table-cell !important;
    @media all and (max-width: $tn) {
      font-size: 1em;
    }
  }
  span.letter {
    @include headerFont($weight: 700);
    font-weight: 400;
    display: table-cell;
    vertical-align: middle;
    float: left;
    width: 14%;
    font-size: 2em;
    box-sizing: border-box;
    text-align: center;
    color: $basic;
  }
  .picked {
    background: $wrong;
    color: $light;
    &:hover {
      background: $wrong;
      color: $light;
      span.letter {
        color: $light !important;
      }
    }
    span.letter {
      background: $wrong;
      color: $light;
    }
  }
  li.correct.picked {
    color: $light;
    background: $correct;
    &:hover {
      background: $correct;
      color: $light;
      span.letter {
        color: $light !important;
      }
    }
    span.letter {
      background: $correct;
    }
  }
}
.answersWrapper {
  @include clearfix;
  color: $basic;
  font-size: 1.35em;
  padding: 20px 35px 20px 35px;
  @media all and (max-width: $tn) {
    padding: 20px 10px;
    margin-bottom: 75px;
  }
}
.quizContent {
  color: $light;
  padding: 0;
  p {
    line-height: 1.25em;
    padding: 20px;
    width: auto;
    font-size: 0.9em;
    position: relative;
    &.question {
      font-family: $primaryFont;
      padding: 20px 45px 20px 0;
      color: $basic;
      font-size: 1.25em;
      text-align: left;
    }
    &.answerCorrect {
      display: none;
      background: $correct;
      font-size: 1em;
      padding: 20px;
      text-align: left;
    }
  }
  p.answerWrong {
    background-color: $wrong;
    display: none;
    background: $wrong;
    font-size: 1em;
    padding: 20px;
    text-align: left;
  }
  .glyphicon {
    font-size: 2.75em;
    padding-right: 10px;
    top: 21px;
    left: -3px;
    position: absolute;
  }
}

/*---Fixes for IE---*/

@media (max-width: $xs) {
  .quizContent {
    p.question {
      padding: 20px 0 20px 0;
      color: #000;
      font-size: 1.125em;
      text-align: center;
      padding-top: 0;
    }
  }
  .quizButtons {
    font-size: 0.9em;
    li {
      border: 1px solid $dark;
    }
    p {
      padding-left: 8px !important;
    }
  }
}
