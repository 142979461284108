

.arrowNav{
    @include clearfix;
    @include borderBox;
    margin-top: 0px;
//    overflow: hidden;
    position: fixed;
    width: 100%;
    top: 40%;
    left: 0px;
    z-index: 125;
    opacity: 1;
    height: 0px !important;
    .arrow{
        width: 50px;
        height: 50px;
        position: absolute;
        top:25%;
        background: $dark;
        color:$light;
        cursor: pointer;
        @include transition;
        border:1px solid $basic;
        &:hover{
            background:$primary;
            border:1px solid $primary;
        }
        span{
            font-size: 2em;
            position: absolute;
            top: 35%;
                @include fillSpace;
        }
        b{
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:0;
            background:$basic;
            @media all and (max-width: $xs){
                display:none!important;
            }
        }
    }
    #arrowLeft{
        left:30px;
        span{
            top:10px;
            left:8px;
        }
    }
    #arrowRight{
        right:30px;
        span{
            top:10px;
            left:11px;
        }
    }
    
}
#arrowLeft.disabled,  #arrowRight.disabled {
    background:transparent;
    span{
        color:$light;
    }
    &:hover{
        cursor: not-allowed;
        border:1px solid $basic;
    }
}

//nav that shows on phone
#footerArrowNav {
    float: right;
    width: 90%;
    .arrow {
        width: 50%;
        @include borderBox;
        float: left;
        border-left: 3px solid $light;
        background: $secondary;
        text-align: center;
        padding-top: 5px;
        @include mainHeadFont;
        font-size: 1.2em;
        font-family: $secondaryFont;
        font-weight: 600;
        cursor: $cursor;
    }
}

@media (max-width:$lg){

}
@media (max-width:$md){
   
}
@media (max-width:$sm){
    .arrowNav{
        position: fixed;
        bottom:0;
        top:auto;
        left:0;
        background:$dark;
        height:40px!important;
        div{
            &.disabled{
                @include opacity(25,.25);
            }
        }
        #arrowLeft{
            position: fixed;
            top: auto!important;
            bottom: 0;
            left: 0;
            height:45px;
            span{
                top:7px;
            }
        }
        #arrowRight{
            position: fixed;
            top: auto!important;
            bottom: 0;
            right: 0;
            height:45px;
            span{
                top:7px;
            }
        }
    }
}
@media (max-width:$xs){
    
}

