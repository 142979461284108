#main-nav {
    list-style: none;
    @include absolute;
    @include fillSpace;
    clear: both;
    @include zeroMargPad;
    @include clearfix;
    @include opacity(0, 0);

    &.out {
        right: -100%;
    }

    .button {
        position: relative;
        list-style: none;
        font-family: $primaryFont;
        text-align: left;
        font-size: 1.75em;
        color: $light;
        display: table;
        width: 40%;

        @media (max-height: 865px) {
            font-size: 1.75em;
            line-height: 2.75em;
        }

        @media (max-height: 680px) {
            font-size: 1.45em;
            line-height: 2.25em;
        }

        &.active {
            a {
                @include transparentBG($primary, .77);
                width: 40%;

                &:hover {
                    background: $primary;

                    .section-title {
                        color: $light;
                    }
                }
            }

            .section-title {
                text-align: right;
                font-size: 1.4em;

                span {
                    padding: 0 20px;
                    font-weight: 100;
                }
            }

            .line {
                display: table;
            }
        }

        &.completed {
            a {
                @include transparentBG($dark, .77);
                width: 40%;

                &:hover {
                    background: $dark;

                    .section-title {
                        color: $light;
                    }
                }
            }

            .section-title {
                text-align: right;
                font-size: 1.4em;

                span {
                    padding: 0 20px;
                    font-weight: 100;
                }
            }

            .line {
                display: table;
                margin-left: -50px;

                span {
                    display: none;
                }

                .checkIcon {
                    display: table-cell;
                    height: 100%;
                    font-size: 1em;

                    &:before {
                        font-family: $iconFont;
                        content: "c";
                    }
                }
            }
        }

        &.available {
            .section-title {
                span {
                    //display: none;
                }
            }
        }

        &.notAvailable {
            .hideUnavailableTopics & {
                display: none;
            }
        }

        a {
            width: 40%;
            display: table-cell;
            height: 100%;
            vertical-align: middle;
            padding-left: 115px;

            &:focus {
                color: $accent;
            }

            &:hover {
                .section-title {
                    color: $primary;
                }
            }
        }

        .line {
            height: 100%;
            @include absolute;

            margin-left: -154px;
            padding-right: 25px;
            display: none;

            span {
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                @include titleFont;
                color: $light;
                text-transform: none;
                font-size: .9em;
            }

            .checkIcon {
                display: none;
            }
        }

        &.notAvailable {

            .section-title {
                span {
                    line-height: 1.25em;

                    @media (min-width:$sm + 1) {
                        float: left;

                    }

                }
            }
        }

        .section-title {
            text-align: left;
            @include titleFont;
            color: $light;
            text-transform: none;
            font-size: 1.125em;
            @include transition;

            .available {
                display: none;
            }

            span.notAvailable {
                display: block;

                font-size: .55em;
                background: $dark;
                padding: 3px 5px;
                border: 1px dashed $primary;
                margin-bottom: 15px;

                @media (min-width:$sm + 1) {
                    float: left;
                    clear: both;
                }

                &:before {
                    //content: "(";
                }

                &:after {
                    //content: ")";
                }

                span {
                    float: none;
                }
            }

            &.hover {
                color: $light;
            }

            span {}
        }
    }
}

.inSection {
    #main-nav {}

    .button {
        cursor: default !important;

        &.active {
            a {
                background: $primary !important;
            }
        }

        &.clicked {
            display: table !important;

            a {
                background: $primary !important;
                width: 40%;

                &:hover {
                    background: $primary;

                    .section-title {
                        color: $light;
                    }
                }
            }

            .section-title {
                text-align: right;
                font-size: 1.4em;

                span {
                    padding: 0 20px;
                    font-weight: 100;
                }
            }

            .line {
                display: table;
            }
        }

        &.completed {
            background: $dark;
        }

        a {
            display: block;
            cursor: default !important;

            &:hover {}

            &:before {
                @include opacity(0, 0);
            }
        }
    }
}

.navControls {
    opacity: 1;

    .arrow {
        width: 84px;
        height: 104px;
        @include rounded(50%);
        position: absolute;
        top: 25%;
        background: $dark;
        color: $light;
        cursor: pointer;
        z-index: 5;

        span {
            font-size: 2em;
            position: absolute;
            top: 35%;
            @include fillSpace;
        }

        &:hover {
            background: $secondary;
        }
    }

    .left {
        left: -45px;

        span {
            right: -50px;
        }
    }

    .right {
        right: -45px;

        span {
            left: 5px;
        }
    }

}

.cloneNavWrap {
    display: none;
}

#offcanvasCover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include transparentBG($basic, 0.75);
    z-index: 2;
}

/*---Fixes for IE---*/
.ie8 #main-nav {}

@media (max-width:$lg) {}

@media (max-width:$md) {
    /* #main-nav{
        .button{
            width:50%;
            &.active{
                width:auto!important;
            }
            .line{
                span{
                    display:none;
                }
            }
        }
    } */
}

@media (max-width:$sm) {
    #main-nav {
        left: 0 !important;
        padding-bottom: 70px;

        &.inside {
            display: none !important;
        }

        .button {
            width: 100% !important;
            height: auto !important;

            &.active {
                width: 100%;

                .line {
                    display: none;
                }
            }

            &.completed {
                .line {
                    margin-left: -12px;
                    top: 8px;
                }
            }

            a {
                padding-left: 0;

                .section-title {
                    text-align: center !important;
                    font-size: 1.2em !important;
                    padding: 5px !important;
                }
            }
        }
    }
}

@media (max-width:$xs) {
    #main-nav {
        position: relative;
        top: auto;
        left: auto;

        .button {
            a {
                .section-title {
                    font-size: 1em !important;
                }
            }
        }
    }
}