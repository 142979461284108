footer{
    height: $footerHeight;
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: $dark;
    z-index: 220;
    @include transition($speed: .5s);
    .logout{
        display: table-cell;
        padding: 15px 25px 15px 25px;
        height: 100%;
        color: $light;
        background: $dark;
        white-space: nowrap;
        @include transition;
        &:hover{
            background:$basic;
        }
    }
}
.inSection{
    .alertBar{
        display: block;
        .alert{
            display: inline-block;float: left;
        }
    }
    footer{
        bottom: - $footerHeight;
        .logout{
            position: fixed;
            bottom: 25px;
            top: auto;
            height: auto;
            right: 30px;
            padding: 5px 15px;
            border-bottom:1px solid $light;
            background:$primary;
       }
    }

}
@media (max-width:$lg){

}
@media (max-width:$md){

}
@media (max-width:$sm){
    footer{
        height:auto;
    }
    .inSection{
        footer{
            .logout{
                bottom:45px;
                right:0;
                width:auto;
                border-bottom:1px solid;
            }
        }
    }
}
@media (max-width:$xs){

}
